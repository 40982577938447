import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "../styles/components/Navbar.css"; // Import the CSS file

const Navbar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleNav}
          aria-expanded={isNavOpen ? "true" : "false"}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className={`collapse navbar-collapse ${
            isNavOpen ? "show" : ""
          } overlay-nav`}
        >
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <NavLink
                to="/"
                exact
                className="nav-link"
                activeClassName="active"
                onClick={toggleNav}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/ServicesPage"
                className="nav-link"
                activeClassName="active"
                onClick={toggleNav}
              >
                Services
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/CareersPage"
                className="nav-link"
                activeClassName="active"
                onClick={toggleNav}
              >
                Careers
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/AboutUsPage"
                className="nav-link"
                activeClassName="active"
                onClick={toggleNav}
              >
                About Us
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/ContactUsPage"
                className="nav-link"
                activeClassName="active"
                onClick={toggleNav}
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
