// Header.js

import React from "react";
import Logo from "./Logo";
import Navbar from "./Navbar";
import "../styles/components/Header.css";
import "../styles/components/Navbar.css"; // Import the CSS file
import "../styles/components/Logo.css";

const Header = () => {
  return (
    <div className="header">
      <div>
        {/* Logo and Navbar */}
        <div className="col-md-12 d-flex justify-content-between">
          <Logo />
          <Navbar />
        </div>
      </div>
    </div>
  );
};

export default Header;

// const Header = () => {
//   return (
//     <div className="header">
//       <div>
//         <div className="row align-items-center">
//           {/* Logo and Navbar */}
//           <div className="col-md-6 col-sm-12 d-flex align-items-center">
//             <Logo />
//             <Navbar />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
