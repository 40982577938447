import React, { useState, useEffect } from "react";
import "../styles/components/Footer.css"; // Import the CSS file

import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaPhoneAlt,
} from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";
import { FaLocationDot } from "react-icons/fa6";
import { MdAttachEmail } from "react-icons/md";
import { ImLocation } from "react-icons/im";
import mapImage from "../images/map_image.jpg"; // Replace with the actual path to your image file
import logoImage from "../images/logo_white_foreground.png"; // Replace with the actual path to your image file

const Footer = () => {
  // Define the LinkedIn profile URL with the correct protocol
  const linkedinProfileUrl =
    "https://www.linkedin.com/in/armaghan-mubeen-butt-5257a6224";
  const googleMapUrl = "https://www.google.com/maps?q=YourDesiredLocation";
  const email = "info@mdoffices.org";
  // Define the handleEmailClick function
  const handleEmailClick = () => {
    // Create a mailto link
    const mailtoLink = `mailto:${email}`;

    // Open the default email client when clicked
    window.location.href = mailtoLink;
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth < 769);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 769);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="BlueFooter">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-3 col-12 ft-1">
            <div style={{ display: "flex", alignItems: "start" }}>
              <img
                src={logoImage}
                alt="Image Description"
                style={{
                  width: "1.4em",
                  marginRight: ".4em",

                  marginTop: ".1em",
                }}
              />
              <h5 style={{ fontSize: "1.5em" }}>MDoffices</h5>
            </div>

            <p>
              Welcome to MDoffices, where we revolutionize healthcare management
              with a commitment to excellence. Check Your Practice Pulse with
              our comprehensive suite of services. Join us in elevating your
              practice to new heights of success and efficiency.
            </p>
          </div>
          <div className="col-md-6 col-lg-3 col-12 ft-2">
            <h5>Our Services</h5>
            <ul>
              <li className="nav-item">
                {/* <a className="" href="/"> */}
                Revenue Cycle Management
                {/* </a> */}
              </li>
              <li className="nav-item">
                {/* <a className="" href="/"> */}
                Practice Management
                {/* </a> */}
              </li>
              <li className="nav-item">
                {/* <a className="" href="/"> */}
                Staff Recruitment
                {/* </a> */}
              </li>
              <li className="nav-item">
                {/* <a className="" href="/"> */}
                Financial Analysis
                {/* </a> */}
              </li>

              {/* Add more links as needed */}
            </ul>
          </div>
          <div className="col-md-6 col-lg-3 col-12 ft-3">
            <h5>Contact Info</h5>
            <p>
              <div style={{ color: "white" }}>
                <span style={{ marginRight: ".6em" }}>
                  <FaPhoneAlt size={15} style={{ color: "white" }} />
                </span>
                <a
                  href="tel:+14699188418"
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontSize: "1em",
                  }}
                >
                  +1 (469) 918-8418
                </a>
              </div>
            </p>
            <p onClick={handleEmailClick} style={{ cursor: "pointer" }}>
              <span style={{ marginRight: ".6em" }}>
                <MdAttachEmail size={15} style={{ color: "white" }} />
              </span>
              <span style={{ color: "white", fontSize: "1em" }}>{email}</span>
            </p>

            <div style={{ color: "white" }}>
              <span style={{ marginRight: ".6em" }}>
                <FaLocationDot size={15} style={{ color: "white" }} />
              </span>
              <a
                href="https://maps.app.goo.gl/vaosqQMtUNgntCUs7"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "white",
                  fontSize: "1em",
                }}
              >
                3447 Renner Rd, #120 Plano TX 75074
              </a>
            </div>
          </div>
          <div
            className="col-md-6 col-lg-3 col-12 ft-4"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              marginTop: isMobile ? "7%" : "0",
              marginBottom: "1em ",
            }}
          >
            <div
              style={{
                background: "#a0a0a0",
                borderRadius: "10px",
                padding: ".14em",
                width: "40%",
                margin: "0 auto",
              }}
            >
              <h6 style={{ margin: "0", fontSize: ".88em" }}>
                <span
                  style={{
                    color: "#33dad0",
                    fontWeight: "bold",
                    fontSize: "1.47em",
                  }}
                >
                  +
                </span>{" "}
                Map Us Fast
              </h6>
            </div>
            <div
              style={{
                position: "relative",
                overflow: "hidden",
                borderRadius: "8%",
                marginTop: "1em",
                width: "11.5em",
                height: "7em",
              }}
            >
              <a
                href="https://maps.app.goo.gl/vaosqQMtUNgntCUs7"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={mapImage}
                  alt="Description of your image"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "8%",
                    cursor: "pointer",
                  }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center Last-footer" style={{ marginTop: "1.3em" }}>
        {/* Add social media links in a horizontal row */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* LinkedIn */}
          <a
            // href={linkedinProfileUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              backgroundColor: "#3b5998",
              color: "white",
              padding: ".63em",
              margin: "0 .37em", // Add spacing between circles
              borderRadius: "50%", // Make it a circle
              display: "inline-flex", // Ensure that it takes the shape of its contents
              alignItems: "center", // Align the content vertically
              justifyContent: "center", // Align the content horizontally
            }}
          >
            <FaFacebookF size={17} style={{ color: "white" }} />
          </a>
          {/* LinkedIn */}
          <a
            // href={linkedinProfileUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              backgroundColor: "#0075b5",
              color: "white",
              padding: ".63em",
              margin: "0 .37em", // Add spacing between circles
              borderRadius: "50%", // Make it a circle
              display: "inline-flex", // Ensure that it takes the shape of its contents
              alignItems: "center", // Align the content vertically
              justifyContent: "center", // Align the content horizontally
            }}
          >
            <FaLinkedinIn size={17} style={{ color: "white" }} />
          </a>

          {/* LinkedIn */}
          <a
            // href={linkedinProfileUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              backgroundColor: "#1d9bf0",
              color: "white",
              padding: ".63em",
              margin: "0 .37em", // Add spacing between circles
              borderRadius: "50%", // Make it a circle
              display: "inline-flex", // Ensure that it takes the shape of its contents
              alignItems: "center", // Align the content vertically
              justifyContent: "center", // Align the content horizontally
            }}
          >
            <FaTwitter size={17} style={{ color: "white" }} />
          </a>

          {/* LinkedIn */}
          <a
            // href={linkedinProfileUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              backgroundColor: "#59ce72",
              color: "white",
              padding: ".63em",
              margin: "0 .37em", // Add spacing between circles
              borderRadius: "50%", // Make it a circle
              display: "inline-flex", // Ensure that it takes the shape of its contents
              alignItems: "center", // Align the content vertically
              justifyContent: "center", // Align the content horizontally
            }}
          >
            <BsWhatsapp size={17} style={{ color: "white" }} />
          </a>

          {/* Add more social media links here with similar styling */}
        </div>

        {/* Developed By Armaghan Mubeen */}
        <a
          href={linkedinProfileUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            backgroundColor: "#002d68", // Darker color than the footer
            color: "white",
            display: "block",
            padding: ".9em 0", // Adjust padding as needed
            textDecoration: "none",
            borderRadius: "0",
            marginTop: "2.9em", // Adjust margin for spacing
            fontSize: ".69em",
          }}
        >
          Designed And Developed By Armaghan Mubeen 🔗
        </a>
      </div>
    </div>
  );
};

export default Footer;
