import React from "react";
import "../styles/pages/HomePage.css"; // Import the CSS file
import Header from "../components/Header";
import Footer from "../components/Footer";
import ImageSection from "../components/ImageSection";
import WhyMDoffices from "../components/WhyMDoffices";
import BlueSection from "../components/BlueSection";

const HomePage = () => {
  return (
    <>
      <Header />
      <ImageSection title="MDoffices Healthcare Management" />
      <WhyMDoffices />

      <BlueSection />

      <Footer />
    </>
  );
};

export default HomePage;
