// ContactUsPage.jsx

import React, { useRef, useState, useEffect } from "react";

import "../styles/pages/ContactUsPage.css"; // Import the CSS file
import ContactUsForm from "../components/ContactUsForm";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ImageSection from "../components/ImageSection";
import BookAppointmentForm from "../components/BookAppointmentForm";

const ContactUsPage = () => {
  const bookAppointmentFormRef = useRef(null);

  const handleBookConsultationClick = () => {
    // Scroll to the BookAppointmentForm section
    bookAppointmentFormRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth < 769);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {" "}
      <Header />
      {/* Your main content goes here */}
      <ImageSection
        title="Contact MDoffices Healthcare Management"
        onBookConsultationClick={handleBookConsultationClick}
      />
      <h1
        style={{
          textAlign: "center",
          fontSize: isMobile ? "1.7em" : "2em",
          color: "#4c4c4c", // Dark grey hex code
          fontWeight: "bold",
          marginLeft: "3.3%",
          marginRight: "3.3%",

          marginTop: isMobile ? "8%" : "3.3%",
        }}
      >
        How Can We Assist You?
      </h1>
      <div style={{ marginTop: isMobile ? "7.2%" : "4.95%" }}></div>
      <div className="container">
        <div className="row">
          <div className="col-md-6" ref={bookAppointmentFormRef}>
            <BookAppointmentForm messageRows={6} />
          </div>
          <div className="col-md-6 mt-md-0 mt-4">
            <ContactUsForm />
          </div>
          <div style={{ marginBottom: "7.6%" }}></div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUsPage;
