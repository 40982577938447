import React, { useState, useEffect } from "react";
import "../styles/pages/AboutUsPage.css"; // Import the CSS file
import Header from "../components/Header";
import Footer from "../components/Footer";
import ImageSection from "../components/ImageSection";
import myImage from "../images/picture-of_managing_director.png"; // Replace with the actual path to your image file
import myImage2 from "../images/picture-of_chief_technology_officer.png"; // Replace with the actual path to your image file
import { MdOutlineHealthAndSafety } from "react-icons/md";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import {
  MdOutlineLeaderboard,
  MdOutlinePublic,
  MdDynamicFeed,
} from "react-icons/md";
import { PiStrategyBold, PiBrain } from "react-icons/pi";
import { AiOutlineGlobal } from "react-icons/ai";
import { GrTechnology } from "react-icons/gr";
import { GrCloudSoftware } from "react-icons/gr";
import { HiOutlineLightBulb } from "react-icons/hi";

import { LiaFileContractSolid } from "react-icons/lia";

const SkillsComponent = ({ icon, skill, description }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 769);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="col-md-4 mb-4 text-center" style={{ width: "100%" }}>
      <div
        className="bg-light rounded-circle text-dark p-3 d-flex mx-auto"
        style={{ width: "3.1em", height: "3.1em" }}
      >
        {icon &&
          React.createElement(icon, { size: "1.55em", className: "mx-auto" })}
      </div>
      <h4
        className="mt-3"
        style={{ fontSize: isMobile ? ".9em" : ".94em", textAlign: "center" }}
      >
        {skill}
      </h4>
      <p className="mx-auto" style={{ fontSize: isMobile ? ".7em" : ".75em" }}>
        {description}
      </p>
    </div>
  );
};

const AboutUsPage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <Header />
      <ImageSection title="About MDoffices Healthcare Management" />
      <div
        style={{
          maxWidth: "1400px",
          margin: "0 auto", // Centering the content horizontally
        }}
      >
        <div style={{ marginTop: "3.3%" }}></div>
        <h1
          style={{
            textAlign: "center",
            fontSize: "2em",
            color: "#4c4c4c",
            fontWeight: "bold",
            marginTop: isMobile ? "8%" : "3.3%",
            marginBottom: isMobile ? "5%" : "0",
          }}
        >
          About MDoffices
        </h1>
        <div style={{ marginTop: isMobile ? "7.2%" : "4.95%" }}></div>
        <div
          className="row"
          style={{
            marginBottom: "5.4%",
            marginLeft: "3.3%",
            marginRight: "3.3%",
          }}
        >
          <div className="col-md-6">
            <div
              style={{
                maxWidth: isMobile ? "100%" : "90%",
                overflow: "hidden",
                borderRadius: isMobile ? "2% 2% 2% 2%" : "2% 0 0 2%", // Setting borderRadius for left corners
              }}
            >
              <img
                src={myImage}
                alt="Description of your image"
                style={{ width: "100%", height: "auto", objectFit: "contain" }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <h4
              style={{ fontWeight: "700", marginTop: isMobile ? "1em" : "0" }}
            >
              Meet Our Managing Director
            </h4>
            {/* <p style={{ fontStyle: "italic" }}>
              
            </p> */}
            <p
              style={{
                color: "#808080",
                fontWeight: "600",
                fontStyle: "italic",
                marginTop: "3.2%",
                marginBottom: "3.2%",
                fontSize: isMobile ? ".9em" : "",
              }}
            >
              {'"'}Greetings! I am Mr. Muhammad Kashif Butt, a seasoned
              professional with over 20 years of experience in Financial &
              Strategic Management and as a Chartered Accountant. Throughout my
              career, I've contributed to various sectors, including Helthcare
              Management Systems, Financial Services, Retail FMCG, EPC
              Contracts, and Public sector. Connect with me{" "}
              <a
                href="https://www.linkedin.com/in/muhammad-kashif-butt-aca-76b80117/"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              {'."'}
            </p>
            <div className="row justify-content-between">
              <div className="col">
                <SkillsComponent
                  icon={MdOutlineHealthAndSafety}
                  skill="Healthcare Management"
                  description="Specialized in healthcare management for legal compliance and strategic growth.

                  "
                />
              </div>
              <div className="col">
                <SkillsComponent
                  icon={FaRegMoneyBillAlt}
                  skill="Financial Management
                  "
                  description="Expert in financial analysis, ERP systems (Oracle, SAP-HANA), and corporate finance.

                  "
                />
              </div>
              <div className="col">
                <SkillsComponent
                  icon={LiaFileContractSolid}
                  skill="EPC Contracts
                  "
                  description="Experienced in managing EPC contracts in healthcare.

                  "
                />
              </div>
            </div>

            <div className="row mt-4 justify-content-between">
              <div className="col">
                <SkillsComponent
                  icon={MdOutlineLeaderboard}
                  skill="Inspirational Leadership"
                  description="Effective leader guiding teams to organizational goals.

                  "
                />
              </div>
              <div className="col">
                <SkillsComponent
                  icon={PiStrategyBold}
                  skill="Strategic Planning"
                  description="Proven in developing and executing strategic plans.

                  "
                />
              </div>
              <div className="col">
                <SkillsComponent
                  icon={MdOutlinePublic}
                  skill="Public Sector
                  "
                  description="Understands financial management in the healthcare public sector.

                  "
                />
              </div>
            </div>
          </div>
        </div>
        <hr className="my-4" style={{ margin: isMobile ? "0 3.3%" : "0" }} />
        <div
          className="row"
          style={{
            marginTop: isMobile ? "10%" : "5.4%",
            marginLeft: "3.3%",
            marginRight: "3.3%",
          }}
        >
          <div className="col-md-6 justify-content-end">
            {" "}
            {/* Added d-flex and justify-content-end */}
            <div
              style={{
                maxWidth: isMobile ? "100%" : "90%",
                overflow: "hidden",
                borderRadius: isMobile ? "2% 2% 2% 2% " : "0 2% 2% 0", // Setting borderRadius for left corners
              }}
            >
              <img
                src={myImage2}
                alt="Description of your image"
                style={{ width: "100%", height: "auto", objectFit: "contain" }}
              />
            </div>
          </div>

          <div className="col-md-6">
            <h4
              style={{ fontWeight: "700", marginTop: isMobile ? "1em" : "0" }}
            >
              Meet Our Chief Technology Officer
            </h4>
            <p
              style={{
                color: "#808080",
                fontWeight: "600",
                fontStyle: "italic",
                marginTop: "3.2%",
                marginBottom: "3.2%",
                fontSize: isMobile ? ".9em" : "",
              }}
            >
              {'"'}Greetings! I am Mr. Armaghan Mubeen Butt, bring a wealth of
              experience and a proven track record in steering teams toward
              organizational success. My leadership style is characterized by
              effective guidance and strategic direction. I have a keen
              understanding of the intricate balance between technology and
              healthcare, ensuring our solutions not only meet industry
              standards but also drive innovation in the field. Connect with me
              here.{" "}
              <a
                href="https://www.linkedin.com/in/armaghan-mubeen-butt-5257a6224"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              {'."'}
            </p>
            <div className="row justify-content-between">
              <div className="col">
                <SkillsComponent
                  icon={AiOutlineGlobal}
                  skill="Cross-Industry Expertise"
                  description="Adaptable tech solutions across healthcare, finance, and diverse sectors.

                  "
                />
              </div>
              <div className="col">
                <SkillsComponent
                  icon={GrTechnology}
                  skill="Technology Leadership"
                  description="Guides teams to success with innovative tech strategies.

                  "
                />
              </div>
              <div className="col">
                <SkillsComponent
                  icon={GrCloudSoftware}
                  skill="Software Architecture"
                  description="Crafts robust solutions with cutting-edge tech.

                  "
                />
              </div>
            </div>{" "}
            <div className="row mt-4 justify-content-between">
              <div className="col">
                <SkillsComponent
                  icon={PiBrain}
                  skill="Expert Problem Solver"
                  description="Proficient in solving complex challenges effectively.

                  "
                />
              </div>
              <div className="col">
                <SkillsComponent
                  icon={MdDynamicFeed}
                  skill="Dynamic Collaboration"
                  description="Fosters teamwork for exceptional results.

                  "
                />
              </div>
              <div className="col">
                <SkillsComponent
                  icon={HiOutlineLightBulb}
                  skill="Visionary Approach"
                  description="Mastermind behind long-term growth strategies.





                   "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "5.4%" }}></div>

      <Footer />
    </>
  );
};

export default AboutUsPage;
