import React from "react";
import "../styles/components/Logo.css"; // Import the CSS file
import logoImage from "../images/logo_blue_foreground.png"; // Replace with the actual path to your image file

const Logo = () => {
  return (
    <div className="logo">
      <div className="row-container">
        <img className="logo-image" src={logoImage} />
        <div className="logo-text">
          <span className="light-blue">MD</span>
          <span className="dark-blue">offices</span>
        </div>
      </div>
      <div className="text-below">CHECK YOUR PRACTICE PULSE </div>
    </div>
  );
};

export default Logo;
