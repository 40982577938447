import React from "react";

import HomePage from "./pages/HomePage";
import ServicesPage from "./pages/ServicesPage";
import CareersPage from "./pages/CareersPage";
import ContactUsPage from "./pages/ContactUsPage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AboutUsPage from "./pages/AboutUsPage";

const App = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/ServicesPage" element={<ServicesPage />} />
        <Route path="/CareersPage" element={<CareersPage />} />
        <Route path="/ContactUsPage" element={<ContactUsPage />} />
        <Route path="/AboutUsPage" element={<AboutUsPage />} />
      </Routes>
    </Router>
  );
};

export default App;
