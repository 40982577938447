// WhyMDoffices.js
import React, { useState, useEffect } from "react";
import "../styles/components/WhyMDoffices.css"; // Import the CSS file

import myImage1 from "../images/image1.jpg"; // Replace with the actual path to your image file
import myImage2 from "../images/image2.jpg"; // Replace with the actual path to your image file
import myImage3 from "../images/image3.jpg"; // Replace with the actual path to your image file
import myImage4 from "../images/image4.jpg"; // Replace with the actual path to your image file

const WhyMDoffices = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 769);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 769);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div
      className="why-mdoffices"
      style={{
        margin: "0 auto" /* Centers the content */,
        width: "100%" /* Make the container take up 100% of the screen width */,

        padding: isMobile
          ? "5.35% 6.7%"
          : "3.35% 6.7%" /* Centers the content */,

        maxWidth: "1400px" /* Maximum width of the content */,
      }}
    >
      <h1
        style={{
          color: "grey",
          fontSize: isMobile ? "1.8em" : "2.13em",
          fontWeight: "bold",
          marginBottom: ".44em",
        }}
      >
        Why MDoffices?
      </h1>
      <p
        style={{
          fontSize: isMobile ? "1em" : "1.13em",
          marginBottom: "4%",
          width: isMobile ? "100%" : "60%",
        }}
      >
        MDoffices provides exceptional management and revenue cycle management
        tailored for medical practices, ensuring seamless operations and
        financial success.
      </p>
      <div
        className="feature"
        style={{
          width: "100%",
          marginBottom: "1.7%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ width: isMobile ? "100% " : "150%" }}>
          <h2
            style={{
              color: "grey",
              fontSize: isMobile ? "1.3em" : "1.63em",
              fontWeight: "bold",
              marginBottom: "1.4%",
            }}
          >
            {" "}
            <span
              style={{
                color: "#3498db",
                fontWeight: "bold",
                fontSize: isMobile ? "1.1em" : "1.3em",
              }}
            >
              +
            </span>{" "}
            Elevating Healthcare through Personalized Service{" "}
          </h2>
          {isMobile && (
            <div
              style={{
                display: "flex",
                width: "100%",
                margin: "5% 0",
              }}
            >
              <div
                style={{
                  width: "100%",
                }}
              >
                <img
                  src={myImage1}
                  alt="Personalized Service Image"
                  style={{
                    height: "auto",
                    borderRadius: "5%",
                    width: "100%",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </div>
            </div>
          )}
          <p style={{ fontSize: isMobile ? "1em" : "1.1em" }}>
            At MDoffices, we understand the unique needs of each practitioner.
            Our dedicated team provides personalized services that go beyond
            expectations, ensuring you receive the attention and solutions you
            deserve. We take the time to understand your practice, tailoring our
            services to enhance patient care and practice efficiency.
          </p>
        </div>
        {!isMobile && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <img
                src={myImage1}
                alt="Personalized Service Image"
                style={{
                  height: "auto",
                  borderRadius: "5%",
                  width: "50%",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              />
            </div>
          </div>
        )}
      </div>{" "}
      <div
        className="feature"
        style={{
          width: "100%",
          marginBottom: "1.7%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ width: isMobile ? "100% " : "150%" }}>
          <h2
            style={{
              color: "grey",
              fontSize: isMobile ? "1.3em" : "1.63em",
              fontWeight: "bold",
              marginBottom: "1.4%",
            }}
          >
            {" "}
            <span
              style={{
                color: "#3498db",
                fontWeight: "bold",
                fontSize: isMobile ? "1.1em" : "1.3em",
              }}
            >
              +
            </span>{" "}
            Comprehensive Solutions for Holistic Growth{" "}
          </h2>
          {isMobile && (
            <div
              style={{
                display: "flex",
                width: "100%",
                margin: "5% 0",
              }}
            >
              <div
                style={{
                  width: "100%",
                }}
              >
                <img
                  src={myImage2}
                  alt="Personalized Service Image"
                  style={{
                    height: "auto",
                    borderRadius: "5%",
                    width: "100%",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </div>
            </div>
          )}
          <p style={{ fontSize: isMobile ? "1em" : "1.1em" }}>
            MDoffices offers comprehensive solutions designed to meet the
            diverse needs of medical practices. Our comprehensive approach
            encompasses financial management, regulatory compliance, and
            operational efficiency.
          </p>
        </div>
        {!isMobile && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <img
                src={myImage2}
                alt="Personalized Service Image"
                style={{
                  height: "auto",
                  borderRadius: "5%",
                  width: "50%",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              />
            </div>
          </div>
        )}
      </div>{" "}
      <div
        className="feature"
        style={{
          width: "100%",
          marginBottom: "1.7%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ width: isMobile ? "100% " : "150%" }}>
          <h2
            style={{
              color: "grey",
              fontSize: isMobile ? "1.3em" : "1.63em",
              fontWeight: "bold",
              marginBottom: "1.4%",
            }}
          >
            {" "}
            <span
              style={{
                color: "#3498db",
                fontWeight: "bold",
                fontSize: isMobile ? "1.1em" : "1.3em",
              }}
            >
              +
            </span>{" "}
            Seamless Integration for Efficient Operations{" "}
          </h2>
          {isMobile && (
            <div
              style={{
                display: "flex",
                width: "100%",
                margin: "5% 0",
              }}
            >
              <div
                style={{
                  width: "100%",
                }}
              >
                <img
                  src={myImage3}
                  alt="Personalized Service Image"
                  style={{
                    height: "auto",
                    borderRadius: "5%",
                    width: "100%",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </div>
            </div>
          )}
          <p style={{ fontSize: isMobile ? "1em" : "1.1em" }}>
            Our solutions seamlessly integrate with your existing workflows,
            minimizing disruptions and ensuring a smooth transition to enhanced
            management and billing services. Our integration capabilities span
            electronic health record systems, financial software, and
            third-party applications. Experience a seamless flow of information
            for efficient and effective operations.
          </p>
        </div>
        {!isMobile && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <img
                src={myImage3}
                alt="Personalized Service Image"
                style={{
                  height: "auto",
                  borderRadius: "5%",
                  width: "50%",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              />
            </div>
          </div>
        )}
      </div>{" "}
      <div
        className="feature"
        style={{
          width: "100%",
          marginBottom: "1.7%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ width: isMobile ? "100% " : "150%" }}>
          <h2
            style={{
              color: "grey",
              fontSize: isMobile ? "1.3em" : "1.63em",
              fontWeight: "bold",
              marginBottom: "1.4%",
            }}
          >
            {" "}
            <span
              style={{
                color: "#3498db",
                fontWeight: "bold",
                fontSize: isMobile ? "1.1em" : "1.3em",
              }}
            >
              +
            </span>{" "}
            Fueling Financial Growth for Medical Practices{" "}
          </h2>
          {isMobile && (
            <div
              style={{
                width: "100%",
                margin: "5% 0",
              }}
            >
              <div
                style={{
                  width: "100%",
                }}
              >
                <img
                  src={myImage4}
                  alt="Personalized Service Image"
                  style={{
                    height: "auto",
                    borderRadius: "5%",
                    width: "100%",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </div>
            </div>
          )}
          <p style={{ fontSize: isMobile ? "1em" : "1.1em" }}>
            Partner with MDoffices for financial growth. Our strategic solutions
            and personalized approach contribute to the success and prosperity
            of your medical practice. From revenue cycle optimization to
            financial planning, we empower your practice to achieve sustainable
            financial growth.
          </p>
        </div>
        {!isMobile && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <img
                src={myImage4}
                alt="Personalized Service Image"
                style={{
                  height: "auto",
                  borderRadius: "5%",
                  width: "50%",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              />
            </div>
          </div>
        )}
      </div>{" "}
      {/* New section with FeatureRow */}
    </div>
  );
};

export default WhyMDoffices;

// // WhyMDoffices.js
// import React from "react";
// import "../styles/components/WhyMDoffices.css"; // Import the CSS file

// import { RiSecurePaymentLine } from "react-icons/ri";
// import myImage from "../images/image1.jpg"; // Replace with the actual path to your image file
// import myImage2 from "../images/image2.jpg"; // Replace with the actual path to your image file
// import myImage3 from "../images/image3.jpg"; // Replace with the actual path to your image file
// import myImage4 from "../images/image4.jpg"; // Replace with the actual path to your image file

// const WhyMDoffices = () => {
//   return (
//     <div
//       className="why-mdoffices"
//       style={{
//         marginTop: "3.3vw",
//         marginRight: "6.6vw",
//         marginLeft: "6.6vw",
//         display: "flex",
//         alignItems: "center",
//       }}
//     >
//       <div>
//         <h1
//           style={{
//             color: "grey",
//             fontSize: "34px",
//             fontWeight: "bold",
//             marginBottom: "0.99vw",
//           }}
//         >
//           Why MDoffices?
//         </h1>
//         <p
//           style={{
//             fontSize: "18px",
//             marginBottom: "1.32vw",
//             paddingRight: "36.3vw",
//           }}
//         >
//           MDoffices provides exceptional management and revenue cycle management
//           tailored for medical practices, ensuring seamless operations and
//           financial success.
//         </p>
//         <div className="unique-features">
//           <div
//             className="feature"
//             style={{
//               marginBottom: "1.32vw",
//               display: "flex",
//               alignItems: "center",
//             }}
//           >
//             <div>
//               <h2
//                 style={{
//                   color: "grey",
//                   fontSize: isMobile ? "1.2em" :"1.63em",
//                   fontWeight: "bold",
//                   marginBottom: "0.66vw",
//                 }}
//               >
//                 {" "}
//                 <span
//                   style={{
//                     color: "#3498db",
//                     fontWeight: "bold",
//                     fontSize: "32px",
//                   }}
//                 >
//                   +
//                 </span>{" "}
//                 Elevating Healthcare through Personalized Service
//               </h2>
//               <p style={{ fontSize: "18px" }}>
//                 At MDoffices, we understand the unique needs of each
//                 practitioner. Our dedicated team provides personalized services
//                 that go beyond expectations, ensuring you receive the attention
//                 and solutions you deserve. We take the time to understand your
//                 practice, tailoring our services to enhance patient care and
//                 practice efficiency.
//               </p>
//             </div>
//             <div>
//               <img
//                 src={myImage}
//                 alt="Personalized Service Image"
//                 style={{
//

//                   marginLeft: "15vw",
//                   borderRadius: "5% ",

//                   backgroundSize: "cover",
//                   backgroundPosition: "center",
//                 }}
//               />
//             </div>
//           </div>

//           <div
//             className="feature"
//             style={{
//               marginBottom: "1.32vw",
//               display: "flex",
//               alignItems: "center",
//             }}
//           >
//             <div>
//               <h2
//                 style={{
//                   color: "grey",
//                   fontSize: "26px",
//                   fontWeight: "bold",
//                 }}
//               >
//                 {" "}
//                 <span
//                   style={{
//                     color: "#3498db",
//                     fontWeight: "bold",
//                     fontSize: "32px",
//                   }}
//                 >
//                   +
//                 </span>{" "}
//                 Comprehensive Solutions for Holistic Growth
//               </h2>
//               <p style={{ fontSize: "18px" }}>
//                 MDoffices offers comprehensive solutions designed to meet the
//                 diverse needs of medical practices. Our comprehensive approach
//                 encompasses financial management, regulatory compliance, and
//                 operational efficiency.
//               </p>
//             </div>
//             <div>
//               <img
//                 src={myImage2}
//                 alt="Comprehensive Solutions Image"
//                 style={{
//

//                   marginLeft: "15vw",
//                   borderRadius: "5% ",
//                 }}
//               />
//             </div>
//           </div>
//           <div
//             className="feature"
//             style={{
//               marginBottom: "1.32vw",
//               display: "flex",
//               alignItems: "center",
//             }}
//           >
//             <div>
//               <h2
//                 style={{
//                   color: "grey",
//                   fontSize: "26px",
//                   fontWeight: "bold",
//                   marginBottom: "0.66vw",
//                 }}
//               >
//                 {" "}
//                 <span
//                   style={{
//                     color: "#3498db",
//                     fontWeight: "bold",
//                     fontSize: "32px",
//                   }}
//                 >
//                   +
//                 </span>{" "}
//                 Seamless Integration for Efficient Operations
//               </h2>
//               <p style={{ fontSize: "18px" }}>
//                 Our solutions seamlessly integrate with your existing workflows,
//                 minimizing disruptions and ensuring a smooth transition to
//                 enhanced management and billing services. Our integration
//                 capabilities span electronic health record systems, financial
//                 software, and third-party applications. Experience a seamless
//                 flow of information for efficient and effective operations.
//               </p>
//             </div>
//             <div>
//               <img
//                 src={myImage3}
//                 alt="Seamless Integration Image"
//                 style={{
//

//                   marginLeft: "15vw",
//                   borderRadius: "5% ",
//                 }}
//               />
//             </div>
//           </div>

//           <div
//             className="feature"
//             style={{
//               marginBottom: "1.32vw",
//               display: "flex",
//               alignItems: "center",
//             }}
//           >
//             <div>
//               <h2
//                 style={{
//                   color: "grey",
//                   fontSize: "26px",
//                   fontWeight: "bold",
//                   marginBottom: "0.66vw",
//                 }}
//               >
//                 {" "}
//                 <span
//                   style={{
//                     color: "#3498db",
//                     fontWeight: "bold",
//                     fontSize: "32px",
//                   }}
//                 >
//                   +
//                 </span>{" "}
//                 Fueling Financial Growth for Medical Practices
//               </h2>
//               <p style={{ fontSize: "18px" }}>
//                 Partner with MDoffices for financial growth. Our strategic
//                 solutions and personalized approach contribute to the success
//                 and prosperity of your medical practice. From revenue cycle
//                 optimization to financial planning, we empower your practice to
//                 achieve sustainable financial growth.
//               </p>
//             </div>
//             <div>
//               <img
//                 src={myImage4}
//                 alt="Financial Growth Image"
//                 style={{
//
//                   marginLeft: "15vw",
//                   borderRadius: "5% ",
//                 }}
//               />
//             </div>
//           </div>
//         </div>
//         {/* New section with FeatureRow */}
//         <div
//           style={{
//             textAlign: "center",
//             margin: "3.3vw",
//             marginTop: "3.3vw",
//           }}
//         ></div>
//       </div>
//     </div>
//   );
// };

// export default WhyMDoffices;
