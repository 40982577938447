// Import necessary components and icons
import React from "react";
import { CiMedicalClipboard } from "react-icons/ci";
import { MdManageHistory, MdAttachMoney } from "react-icons/md";
import { LiaUserNurseSolid } from "react-icons/lia";
import { RiCustomerService2Line } from "react-icons/ri";
import { VscFileSymlinkDirectory } from "react-icons/vsc";
// import { useNavigate } from "react-router-dom";
import "../styles/components/Services.css"; // Import the CSS file

import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const Services = () => {
  const servicesData = [
    {
      icon: <CiMedicalClipboard size={"2.5em"} style={iconStyle} />,
      title: "Revenue Cycle Management",
      items: [
        "Insurance verification",
        "Coding and documentation",
        "Medical billing services",
        "Financial Analysis & Report",
      ],
    },
    {
      icon: <MdManageHistory size={"2.5em"} style={iconStyle} />,
      title: "Practice Management",
      items: [
        "Patient scheduling and appointment management",
        "Electronic health records (EHR) management",
        "Patient data security and privacy",
      ],
    },
    {
      icon: <LiaUserNurseSolid size={"2.5em"} style={iconStyle} />,
      title: "Staff Recruitment",
      items: [
        "Qualified healthcare professional recruitment",

        "Effective onboarding processes for seamless integration",

        "Adherence to industry regulations and compliance",
      ],
    },
    {
      icon: <RiCustomerService2Line size={"2.5em"} style={iconStyle} />,
      title: "Customer Service",
      items: [
        "Patient support and inquiries        ",
        "Billing inquiries and dispute resolution ",
        "General customer assistance        ",
      ],
    },
    // Add more services as needed
    {
      icon: <HiOutlineBuildingOffice2 size={"2.5em"} style={iconStyle} />,
      title: "Office Management",
      items: [
        "Administrative support        ",
        "Facility management",
        "Staff coordination and supervision",
        "Resource allocation",
      ],
    },
    {
      icon: <VscFileSymlinkDirectory size={"2.5em"} style={iconStyle} />,
      title: "Managing Directory Services",
      items: [
        "Business development and growth services",
        "Strategic planning and decision-making        ",
      ],
    },
  ];

  return (
    <div className="services-container">
      {servicesData.map((service, index) => (
        <div key={index} className="service-item">
          <div className="circle-container">{service.icon}</div>
          <div>
            <h3>{service.title}</h3>
            <ul>
              {service.items.map((item, itemIndex) => (
                <li key={itemIndex} style={{ listStyle: "none" }}>
                  <span style={{ fontSize: "1.1em", marginRight: "0.5em" }}>
                    •
                  </span>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};
const iconStyle = {
  color: "white",
};
export default Services;

// Function to chunk array into groups
// const chunkArray = (array, size) => {
//   const result = [];
//   for (let i = 0; i < array.length; i += size) {
//     result.push(array.slice(i, i + size));
//   }
//   return result;
// };

// Styles (unchanged from your original code)
// Styles
// const containerStyle = (backgroundColor) => ({
//   backgroundColor,
//   borderRadius: "15px",
//   flex: "1 1 30%", // Allow flexible sizing with a maximum width of 30%
//   margin: "0 1.5%",
//   maxWidth: "21.5%", // Set a maximum width for the containers
//   boxShadow: "0 16px 32px rgba(0, 0, 0, 0.2)",
//   // cursor: "pointer", // Add this line to change cursor to pointer
// });

// const circleContainerStyle = {
//   width: "4.5em",
//   height: "4.5em",
//   borderRadius: "50%",
//   background: "#3498db",
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   margin: "7% auto",
// };

// const headingStyle = {
//   color: "#4c4c4c",
//   fontSize: "1.2em",
//   textAlign: "center",
// };

// const listStyle = {
//   color: "grey",
//   listStyleType: "disc",
//   fontSize: "1em",
//   marginRight: "5%",
//   marginLeft: "5%",
//   marginTop: "15px",
//   marginBottom: "20px",
// };
