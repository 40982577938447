import React, { useState, useEffect } from "react";
import "../styles/components/ImageSection.css"; // Import the CSS file
import HealthcareImage1 from "../images/healthcare_interaction_1.png";
import HealthcareImage2 from "../images/healthcare_interaction_2.png";
import HealthcareImage3 from "../images/healthcare_interaction_3.jpg";
import HealthcareImageCroped1 from "../images/healthcare_interaction_1_croped.png";
import HealthcareImageCroped2 from "../images/healthcare_interaction_2_croped.png";
import HealthcareImageCroped3 from "../images/healthcare_interaction_3_croped.jpg";
import BookAppointmentForm from "./BookAppointmentForm";
import { FaTimes } from "react-icons/fa";

const ImageSection = ({ title, onBookConsultationClick, forCarrersPage }) => {
  const images = [
    HealthcareImage1,
    HealthcareImage2,
    HealthcareImage3,
    // Add more image URLs as needed
  ];

  const croppedImages = [
    HealthcareImageCroped1,
    HealthcareImageCroped2,
    HealthcareImageCroped3,
    // Add more cropped image URLs as needed
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 769);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 769);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      goToNextImage();
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [currentImageIndex]);

  const goToNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  // const goToPreviousImage = () => {
  //   setCurrentImageIndex((prevIndex) =>
  //     prevIndex === 0 ? images.length - 1 : prevIndex - 1
  //   );
  // };

  const handleButtonClick = () => {
    if (forCarrersPage) {
      const generateMailtoLink = () => {
        return `mailto:jobs@mdoffices.org?subject=Application for Career At MDoffices&body=Thank you for selecting MDoffices for your career journey. Please share your resume and cover letter for current and future opportunities.`;
      };
      const mailtoLink = generateMailtoLink();
      window.location.href = mailtoLink;
    } else if (onBookConsultationClick) {
      onBookConsultationClick();
    } else {
      toggleForm();
    }
  };

  return (
    <div className="image-section">
      <img
        src={
          isMobile
            ? images[currentImageIndex]
            : croppedImages[currentImageIndex]
        }
        alt="Healthcare Services"
        className="img-fluid"
        style={{ objectFit: "cover" }}
      />
      <div className="image-overlay">
        <h1>{title}</h1>
        <div className="buttons">
          <button
            className="btn btn-primary"
            onClick={() => {
              handleButtonClick();
            }}
          >
            {forCarrersPage
              ? "Apply For Registration"
              : "Let's Book a Consultation"}
          </button>
        </div>
      </div>
      {showForm && (
        <div className="form-overlay">
          <div className="form-container">
            <button className="close-btn" onClick={handleButtonClick}>
              <FaTimes color="white" />
            </button>
            <BookAppointmentForm messageRows={1} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageSection;
