import React, { useState, useEffect } from "react";
import "../styles/pages/CareersPage.css"; // Import the CSS file
import Footer from "../components/Footer";
import Header from "../components/Header";
import ImageSection from "../components/ImageSection";
import logo from "../images/logo_white.png";

const CareersPage = () => {
  const handleApplyClick = (jobTitle) => {
    const subject = encodeURIComponent(`Job Application For ${jobTitle}`);
    const message = encodeURIComponent(
      `Dear Applicant,\n\nThank you for applying for the position of ${jobTitle} at MDoffices. 
      We would appreciate it if you could attach your resume and cover letter to this email.
      Your application will be carefully reviewed, and we will contact you if your qualifications match our requirements.\n\nBest Regards,\nHuman Resource MDoffices`
    );
    const mailtoLink = `mailto:jobs@mdoffices.org?subject=${subject}&body=${message}`;
    window.location.href = mailtoLink;
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth < 769);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {" "}
      <Header />
      {/* Your main content goes here */}
      <ImageSection
        title="Start Your Career With MDoffices"
        forCarrersPage={true}
      />
      <h1
        style={{
          textAlign: "center",
          fontSize: isMobile ? "1.7em" : "2em",
          color: "#4c4c4c", // Dark grey hex code
          fontWeight: "bold",
          marginTop: isMobile ? "8%" : "3.3%",
          marginLeft: "3.3%",
          marginRight: "3.3%",
        }}
      >
        Current Jobs Opening
      </h1>
      <div style={{ marginTop: "4.95%" }}></div>
      <div
        className="container"
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: "flex-start",
        }}
      >
        <div
          className="left-column"
          style={{
            flex: 2,
          }}
        >
          <h3
            style={{
              textAlign: "center",
              color: "grey",
              fontWeight: "bold",
              // Remove or reduce marginTop to align to the start
              marginTop: isMobile ? "5%" : "4%", // Adjust as needed
              textAlign: "left",
              marginLeft: "3.3%",
              marginRight: "3.3%",
            }}
          >
            Join Our Team at MDoffices
          </h3>
          <ul>
            <li className="bold-blue-text">
              Be a vital part of our mission-driven workplace.
            </li>
            <li className="bold-blue-text">
              Contribute to a dynamic and collaborative team environment.
            </li>
            <li className="bold-blue-text">
              Explore growth opportunities in a forward-thinking organization.
            </li>
            <li className="bold-blue-text">
              Enjoy a positive and inclusive work culture.
            </li>
            <li className="bold-blue-text">
              Make a meaningful impact on the healthcare industry.
            </li>
            <li className="bold-blue-text">
              Engage in ongoing professional development.
            </li>
            <li className="bold-blue-text">
              Participate in team-building activities and events.
            </li>
            <li className="bold-blue-text">
              Collaborate with experienced professionals in the field.
            </li>
            <li className="bold-blue-text">
              Shape the future of healthcare management with us.{" "}
            </li>
            {/* Add more bullet points as needed */}
          </ul>
        </div>
        <div style={{ flex: 3 }}>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              marginTop: isMobile ? "10%" : "2.5%",
            }}
          >
            <div
              className="logo"
              style={{
                flex: 2,
                marginTop: "1%",
                marginRight: "2.5%",
                maxWidth: "3em",
                height: "3em",
                overflow: "hidden",
                borderRadius: "50%",
                alignSelf: "flex-start", // Align the image to the start vertically
              }}
            >
              <img
                src={logo}
                alt=""
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
            <div className="right-column">
              <div>
                <div className="MDoffices">
                  <span className="cname">
                    {" "}
                    Customer Service Representative
                  </span>
                </div>
                <div className="SoftwareDeveloper">MDoffices</div>
                <div className="details">
                  <span>Dallas, USA</span>
                  <span>&nbsp;•&nbsp;</span>
                  <span>Full Time (On-site)</span>
                </div>
                <div style={{ marginLeft: "auto" }}>
                  <button
                    style={{
                      backgroundColor: "#005A9c",
                      color: "#fff",
                      padding: "3.5% 7%",
                      borderRadius: "5%",
                      cursor: "pointer",
                      marginTop: "15%",
                      marginBottom: "13%",
                    }}
                    onClick={() =>
                      handleApplyClick("Customer Service Representative")
                    }
                  >
                    Apply Now
                  </button>
                </div>
              </div>
              <hr
                style={{
                  width: "100%",
                  background: "grey",
                  margin: "3.5% 0",
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              marginTop: "2.5%",
            }}
          >
            <div
              className="logo"
              style={{
                flex: 2,
                marginTop: "1%",
                marginRight: "2.5%",
                maxWidth: "3em",
                height: "3em",
                overflow: "hidden",
                borderRadius: "50%",
                alignSelf: "flex-start", // Align the image to the start vertically
              }}
            >
              <img
                src={logo}
                alt=""
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
            <div className="right-column">
              <div>
                <div className="MDoffices">
                  <span className="cname">Office Manager</span>
                </div>
                <div className="SoftwareDeveloper">MDoffices</div>
                <div className="details">
                  <span>Dallas, USA</span>
                  <span>&nbsp;•&nbsp;</span>
                  <span>Full Time (On-site)</span>
                </div>
                <div style={{ marginLeft: "auto" }}>
                  <button
                    style={{
                      backgroundColor: "#005A9c",
                      color: "#fff",
                      padding: "3.5% 7%",
                      borderRadius: "5%",
                      cursor: "pointer",
                      marginTop: "15%",
                      marginBottom: "13%",
                    }}
                    onClick={() => handleApplyClick("Office Manager")}
                  >
                    Apply Now
                  </button>
                </div>
              </div>
              <hr
                style={{
                  width: "100%",
                  background: "grey",
                  margin: "3.5% 0",
                }}
              />
            </div>
          </div>{" "}
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              marginTop: "2.5%",
            }}
          >
            <div
              className="logo"
              style={{
                flex: 2,
                marginTop: "1%",
                marginRight: "2.5%",
                maxWidth: "3em",
                height: "3em",
                overflow: "hidden",
                borderRadius: "50%",
                alignSelf: "flex-start", // Align the image to the start vertically
              }}
            >
              <img
                src={logo}
                alt=""
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
            <div className="right-column">
              <div>
                <div className="MDoffices">
                  <span className="cname">Nurse Practitioner</span>
                </div>
                <div className="SoftwareDeveloper">MDoffices</div>
                <div className="details">
                  <span>Dallas, USA</span>
                  <span>&nbsp;•&nbsp;</span>
                  <span>Full Time (On-site)</span>
                </div>
                <div style={{ marginLeft: "auto" }}>
                  <button
                    style={{
                      backgroundColor: "#005A9c",
                      color: "#fff",
                      padding: "3.5% 7%",
                      borderRadius: "5%",
                      cursor: "pointer",
                      marginTop: "15%",
                      marginBottom: "13%",
                    }}
                    onClick={() => handleApplyClick("Nurse Practitioner")}
                  >
                    Apply Now
                  </button>
                </div>
              </div>
              <hr
                style={{
                  width: "100%",
                  background: "grey",
                  margin: "3.5% 0",
                }}
              />
            </div>
          </div>{" "}
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              marginTop: "2.5%",
            }}
          >
            <div
              className="logo"
              style={{
                flex: 2,
                marginTop: "1%",
                marginRight: "2.5%",
                maxWidth: "3em",
                height: "3em",
                overflow: "hidden",
                borderRadius: "50%",
                alignSelf: "flex-start", // Align the image to the start vertically
              }}
            >
              <img
                src={logo}
                alt=""
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
            <div className="right-column">
              <div>
                <div className="MDoffices">
                  <span className="cname">Medical Biller</span>
                </div>
                <div className="SoftwareDeveloper">MDoffices</div>
                <div className="details">
                  <span>Dallas, USA</span>
                  <span>&nbsp;•&nbsp;</span>
                  <span>Full Time (On-site)</span>
                </div>
                <div style={{ marginLeft: "auto" }}>
                  <button
                    style={{
                      backgroundColor: "#005A9c",
                      color: "#fff",
                      padding: "3.5% 7%",
                      borderRadius: "5%",
                      cursor: "pointer",
                      marginTop: "15%",
                      marginBottom: "13%",
                    }}
                    onClick={() => handleApplyClick("Medical Biller")}
                  >
                    Apply Now
                  </button>
                </div>
              </div>
              <hr
                style={{
                  width: "100%",
                  background: "grey",
                  margin: "3.5% 0",
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              marginTop: "2.5%",
            }}
          >
            <div
              className="logo"
              style={{
                flex: 2,
                marginTop: "1%",
                marginRight: "2.5%",
                maxWidth: "3em",
                height: "3em",
                overflow: "hidden",
                borderRadius: "50%",
                alignSelf: "flex-start", // Align the image to the start vertically
              }}
            >
              <img
                src={logo}
                alt=""
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
            <div className="right-column">
              <div>
                <div className="MDoffices">
                  <span className="cname"> Medical Technician</span>
                </div>
                <div className="SoftwareDeveloper">MDoffices</div>
                <div className="details">
                  <span>Dallas, USA</span>
                  <span>&nbsp;•&nbsp;</span>
                  <span>Full Time (On-site)</span>
                </div>
                <div style={{ marginLeft: "auto" }}>
                  <button
                    style={{
                      backgroundColor: "#005A9c",
                      color: "#fff",
                      padding: "3.5% 7%",
                      borderRadius: "5%",
                      cursor: "pointer",
                      marginTop: "15%",
                      marginBottom: "13%",
                    }}
                    onClick={() => handleApplyClick("Medical Technician")}
                  >
                    Apply Now
                  </button>
                </div>
              </div>
              <hr
                style={{
                  width: "100%",
                  background: "grey",
                  margin: "3.5% 0",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "3.3%" }}></div>
      <Footer />
    </>
  );
};

export default CareersPage;
