import React, { useState } from "react";
import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-input-2";
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-input-2/lib/style.css";
import "../styles/components/BookAppointmentForm.css";

const BookAppointmentForm = ({ messageRows }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [appointmentDate, setAppointmentDate] = useState();
  const [message, setMessage] = useState("");

  const [region, setRegion] = useState("");

  // State to track whether required fields are filled
  const [requiredFieldsFilled, setRequiredFieldsFilled] = useState(true);

  const handleDateChange = (date) => {
    setAppointmentDate(date);
  };

  const handlePhoneChange = (value, country) => {
    setContactNumber(value);
  };

  const generateMailtoLink = () => {
    const subject = encodeURIComponent(
      `Request For Booking A Consultaion on ${appointmentDate}`
    );
    const body = encodeURIComponent(
      `Name: ${name}\nEmail: ${email}\nContact Number: +${contactNumber}\nRegion: ${region}\nAppointment Date: ${appointmentDate}\nMessage: ${message}`
    );
    return `mailto:info@mdoffices.org?subject=${subject}&body=${body}`;
  };

  const handleMailtoClick = () => {
    // Check if all required fields are filled
    if (!name || !email || !contactNumber || !region || !appointmentDate) {
      // Set state to indicate that required fields are not filled
      setRequiredFieldsFilled(false);
      return;
    }

    const mailtoLink = generateMailtoLink();
    window.location.href = mailtoLink;
  };

  return (
    <div
      style={{
        backgroundColor: "#6fc0db",
        color: "#fff",
        padding: "1.5rem",
        borderRadius: "8px",
      }}
    >
      <div>
        <h5 className="card-title" style={{ marginBottom: "1rem" }}>
          Book A Consultation
        </h5>
        <form>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Enter your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            {/* Display error message if required field is not filled */}
            {!name && !requiredFieldsFilled && (
              <p
                style={{ color: "red", marginBottom: "0px", fontSize: "14px" }}
              >
                *this field is required
              </p>
            )}
            <div style={{ marginBottom: "10px" }}></div>
          </div>

          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {/* Display error message if required field is not filled */}
            {!email && !requiredFieldsFilled && (
              <p
                style={{ color: "red", marginBottom: "0px", fontSize: "14px" }}
              >
                *this field is required
              </p>
            )}
            <div style={{ marginBottom: "10px" }}></div>
          </div>

          <div className="form-group">
            <label htmlFor="contactNumber">Contact Number:</label>
            <PhoneInput
              country={"us"} // Set the default country
              value={contactNumber}
              onChange={handlePhoneChange}
              inputProps={{
                name: "contactNumber",
                required: true,
              }}
            />
            {/* Display error message if required field is not filled */}
            {!contactNumber && !requiredFieldsFilled && (
              <p
                style={{ color: "red", marginBottom: "0px", fontSize: "14px" }}
              >
                *this field is required
              </p>
            )}
            <div style={{ marginBottom: "10px" }}></div>
          </div>

          <div className="form-group">
            <label htmlFor="region">Region:</label>
            <input
              type="text"
              className="form-control"
              id="region"
              placeholder="Enter your region"
              value={region}
              onChange={(e) => setRegion(e.target.value)}
              required
            />
            {/* Display error message if required field is not filled */}
            {!region && !requiredFieldsFilled && (
              <p
                style={{ color: "red", marginBottom: "0px", fontSize: "14px" }}
              >
                *this field is required
              </p>
            )}
            <div style={{ marginBottom: "10px" }}></div>
          </div>

          <div className="form-group">
            <label htmlFor="appointmentDate">Appointment Date:</label>
            <div className="input-group">
              <DatePicker
                selected={appointmentDate}
                onChange={handleDateChange}
                dateFormat="dd/MM/yyyy"
                className="form-control"
                placeholderText="-- / -- / ----" // Set your desired placeholder text here
              />
            </div>
            {/* Display error message if required field is not filled */}
            {!appointmentDate && !requiredFieldsFilled && (
              <p
                style={{ color: "red", marginBottom: "0px", fontSize: "14px" }}
              >
                *this field is required
              </p>
            )}
            <div style={{ marginBottom: "10px" }}></div>
          </div>

          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea
              className="form-control"
              id="message"
              placeholder="Enter your message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              rows={messageRows}
            ></textarea>
          </div>

          <button
            type="button"
            className="btn btn-light"
            style={{ marginTop: "10px" }}
            onClick={handleMailtoClick}
          >
            Book A Consultation
          </button>
        </form>
      </div>
    </div>
  );
};

export default BookAppointmentForm;
