import React, { useState, useEffect } from "react";
import "../styles/pages/ServicesPage.css"; // Import the CSS file
import Header from "../components/Header";
import Footer from "../components/Footer";
import ImageSection from "../components/ImageSection";
import Services from "../components/Services";

const ServicesPage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 769);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {" "}
      <Header />
      {/* Your main content goes here */}
      <ImageSection title="MDoffices Healthcare Management Services" />
      <div></div>
      <h1
        style={{
          textAlign: "center",
          fontSize: "2em",
          color: "#4c4c4c", // Dark grey hex code
          fontWeight: "bold",
          marginTop: isMobile ? "8%" : "3.3%",
          marginBottom: isMobile ? "5%" : "0",
          marginLeft: "3.3%",
          marginRight: "3.3%",
        }}
      >
        Services We Are Focused On
      </h1>
      <Services
        iconSrc="https://www.svgrepo.com/show/429608/graph-chart-2.svg" // Replace with the actual URL of your icon
        heading="Easy Connectivity"
        description="Connect with your patients seamlessly through our user-friendly telehealth platform. Experience hassle-free communication and collaboration."
      />
      <div style={{ marginTop: "6.6%" }}></div>
      <Footer />
    </>
  );
};

export default ServicesPage;
