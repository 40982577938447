import React, { useState, useEffect } from "react";
import "../styles/components/BlueSection.css"; // Import the CSS file

const BlueSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 769);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 769);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="blue-section">
      <div
        className="left-content"
        style={{ width: isMobile ? "100%" : "80%" }}
      >
        <h2 style={{ fontSize: isMobile ? "1.6em" : "1.88em" }}>
          What's it like to work with MDoffices?
        </h2>
        <p style={{ fontSize: isMobile ? "1em" : "1.13em" }}>
          With MDoffices healthcare management services, you keep your current
          office and staff. We provide oversight and support for all
          administrative functions. Your financials and your medical practice
          remain independent. This short clip will show you how!
        </p>
      </div>

      <div className="right-content">
        <div className="video-container">
          <iframe
            src="https://www.youtube.com/embed/eFWCWgo5hRI"
            title="YouTube video player"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default BlueSection;

// import React from "react";
// import "../styles/components/BlueSection.css"; // Import the CSS file

// const BlueSection = () => {
//   return (
//     <section className="blue-section">
//       <div className="left-content">
//         <h2 style={{ fontSize: "30px" }}>
//           What's it like to work with MDoffices?
//         </h2>
//         <p style={{ fontSize: "18px", width: "46vw" }}>
//           With MDoffices healthcare management services, you keep your current
//           office and staff. We provide oversight and support for all
//           administrative functions. Your financials and your medical practice
//           remain independent. This short clip will show you how!
//         </p>
//       </div>
//       <div className="right-content">
//         <div className="video-container">
//           <iframe
//             // width=""
//             // height="auto"
//             src="https://www.youtube.com/embed/eFWCWgo5hRI"
//             title="YouTube video player"
//             allowFullScreen
//           ></iframe>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default BlueSection;
